import { usePage } from '@griddo/core'
import { useGetLocale } from '@hooks/useGetLocale'
import React, { useEffect, useState } from 'react'

import useRenderer from '@hooks/useRenderer'
import { mountParamsPage } from '@constants/dataLayer'
import { getWindow } from 'ssr-window'

const window = getWindow()

export default function useDataLayer() {
  const locale = useGetLocale()
  const { fullPath, isHome } = usePage()

  const [typeEvent, setTypeEvent] = useState()
  const [templateType, setTemplateType] = useState(null)
  const [extraData, setExtraData] = useState()

  const { isSSG } = useRenderer()

  useEffect(() => {
    if (!!typeEvent && isSSG) {
      import('@utils/DataLayer').then(({ DatalayerCustomEvent }) => {
        DatalayerCustomEvent({
          templateType,
          locale,
          extraData,
          pathname: fullPath?.compose || '',
          eventName: typeEvent,
        })
      })
    }
  }, [typeEvent])

  useEffect(() => {
    if (!!templateType && isSSG) {
      const pageView = mountParamsPage({
        locale,
        isHome,
        templateType,
        pathname: fullPath?.compose || '',
        eventName: 'page_view',
        isCX: false,
      })

      if (pageView?.event && typeof window !== 'undefined') {
        const prev_view = window.sessionStorage.getItem('page_view')
        if (prev_view) window.sessionStorage.setItem('prev_view', prev_view)
        window.sessionStorage.setItem('page_view', JSON.stringify(pageView))
      }
    }
  }, [templateType])

  const handleTypeEvent = ({ data, eventName }) => {
    if (!!eventName && isSSG) {
      import('@utils/DataLayer').then(({ DatalayerCustomEvent }) => {
        DatalayerCustomEvent({
          templateType,
          locale,
          extraData: data,
          pathname: fullPath?.compose || '',
          eventName,
        })
      })
    }
  }

  return {
    extraData,
    setTypeEvent,
    setTemplateType,
    setExtraData,
    handleTypeEvent,
  }
}
