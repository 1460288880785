import thumbnail from '@griddo-images/thumbnails/LandingFooter.png'
import thumbnail2x from '@griddo-images/thumbnails/LandingFooter@2x.png'

export default {
  schemaType: 'module',
  displayName: 'Landing Footer',
  component: 'LandingFooter',
  type: 'footer',
  category: 'navigation',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          key: 'title',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          type: 'UniqueCheck',
          key: 'setAsDefault',
          options: [
            {
              title: 'Set as Footer default',
            },
          ],
        },
        {
          title: 'Select Footer Menu',
          key: 'footerMenu',
          type: 'AsyncSelect',
          entity: 'menu_containers',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'LandingFooter',
    type: 'footer',
    title: 'Landing footer',
  },

  thumbnails: {
    '1x': thumbnail,
    '2x': thumbnail2x,
  },
}
