// import thumbnailsProgramTabElement from '@griddo-images/thumbnails/ProgramTabElement.png';

export default {
  schemaType: 'component',
  displayName: 'Landing Program Tab',
  component: 'LandingProgramTabElement',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          key: 'title',
          hideable: true,
        },
        {
          title: 'Program Tab Elements',
          type: 'ComponentArray',
          mandatory: true,
          whiteList: [
            'AccordionCollection',
            'BasicContent',
            'BasicText',
            'CardCollection',
            'CarouselCollection',
            'ColouredCollection',
            'CypherCollection',
            'DownloadCollection',
            'FeaturedAudiovisual',
            'FeaturedBlock',
            'GoToTop',
            'HorizontalTabs',
            'LogoCollection',
            'Testimonials',
            'LandingProgramCollection',
            'LandingStudyPlan',
          ],
          key: 'componentModules',
        },
      ],
    },
  ],

  default: {
    component: 'LandingProgramTabElement',
    title: 'Tab title',
    componentModules: [
      {
        component: 'LandingProgramCollection',
        icon: {
          alt: '',
          publicId: 'placeholders/griddo-background',
        },
        title: {
          content: 'Title',
          tag: 'h2',
        },
        subtitle: {
          content: 'Subtitle',
          tag: 'h2',
        },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        elements: [
          {
            component: 'LandingProgramCard',
            image: {
              alt: '',
              publicId: 'placeholders/griddo-background',
            },
            title: {
              content: 'Title',
              tag: 'h2',
            },
            detail:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
            start: '¡Start date!',
            duration: 'Duration',
            mode: 'Online',
            language: 'Castellano',
            btnLabel: '¡Inscríbete ya!',
            hasDistributorData: true,
            data: {
              mode: 'manual',
              sources: [{ structuredData: 'AREA' }],
              order: 'recent',
              quantity: 1,
              fullRelations: true,
            },
          },
          {
            component: 'LandingProgramCard',
            image: {
              alt: '',
              publicId: 'placeholders/griddo-background',
            },
            title: {
              content: 'Title',
              tag: 'h2',
            },
            detail:
              'Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
            start: 'Start date',
            duration: 'Duration',
            mode: 'Online',
            language: 'Castellano',
            btnLabel: '¡Inscríbete ya!',
            hasDistributorData: true,
            data: {
              mode: 'manual',
              sources: [{ structuredData: 'AREA' }],
              order: 'recent',
              quantity: 1,
              fullRelations: true,
            },
          },
        ],
        line: true,
        layout: 'vertical',
      },
    ],
  },
  thumbnails: {
    // '1x': thumbnailsProgramTabElement,
    // '2x': thumbnailsProgramTabElement,
  },
}
