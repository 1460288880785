import thumbnailsReportsList from '@griddo-images/thumbnails/ReportsList.png'

import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { basicModuleWhiteList } from '@schemas/modules'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Reports List',
  component: 'ReportsList',
  dataPacks: ['REPORTS'],
  type: { label: 'REPORTS', value: 'REPORTS', mode: 'list' },

  content: [
    {
      title: 'Use image as background',
      type: 'ConditionalField',
      key: 'conditionalBackground',
      mandatory: true,
      options: [
        {
          value: true,
          title: 'Yes',
          name: 'yes',
        },
        {
          value: false,
          title: 'No',
          name: 'no',
        },
      ],
      fields: [
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'backgroundImage',
          condition: true,
          helptext: 'Recommended size: 1920x1080',
        },
      ],
    },
    {
      title: 'Reports Title',
      type: 'HeadingField',
      key: 'reportsTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
    },
    {
      title: 'Icon',
      type: 'ImageField',
      key: 'icon',
      hideable: true,
    },
    {
      title: 'Description',
      type: 'RichText',
      key: 'description',
      html: true,
      hideable: true,
    },
    {
      title: 'No results text',
      type: 'TextField',
      key: 'noResultsText',
    },
    {
      title: 'Items per page',
      type: 'NumberField',
      key: 'itemsPerPage',
      mandatory: true,
    },
    {
      title: '',
      type: 'ReferenceField',
      sources: [{structuredData: 'REPORTS' }],
      key: 'data',
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: basicModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ReportsList',
    conditionalBackground: false,
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 60,
    },
    reportsTitle: { content: 'Title', tag: 'h1' },
    icon: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing.',
    // itemsPerPage: 10,
    noResultsText:
      'Lo sentimos, no hemos encontrado resultados. Por favor, intenta una nueva búsqueda.',
    itemsPerPage: 10,
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{structuredData: 'REPORTS' }],
      order: 'recent',
      quantity: 0,
    },
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsReportsList,
    '2x': thumbnailsReportsList,
  },
}
