import thumbnailsStudyPlan from '@griddo-images/thumbnails/StudyPlan.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Study Plan',
  component: 'StudyPlan',
  category: 'programs',
  dataPacks: ['ODS', 'PROGRAMS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'image',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'ODS List',
          type: 'ReferenceField',
          sources: [{ structuredData: 'ODS' }],
          key: 'data',
          mandatory: false,
        },
        {
          title: 'Years',
          type: 'ComponentContainer',
          maxItems: null,
          mandatory: true,
          key: 'programAccordion',
          whiteList: ['ProgramAccordion'],
        },
      ],
    },
  ],

  default: {
    component: 'StudyPlan',
    image: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    hasDistributorData: true,
    data: {
      mode: 'manual',
      sources: [{ structuredData: 'ODS' }],
      order: 'alpha',
      quantity: 15,
    },
    programAccordion: {
      component: 'ProgramAccordion',
      elements: [
        {
          component: 'ProgramAccordionElement',
          title: { content: 'Primer Curso', tag: 'h2' },
          credits: '60 ECTS',
          programTable: {
            component: 'ProgramTable',
            elements: [
              {
                component: 'ProgramTableColumn',
                title: 'Nº Semestre',
                elements: [
                  {
                    component: 'ProgramTableRow',
                    title: 'Asignatura',
                    credits: '6 ECTS',
                    type: 'Obligatoria',
                  },
                ],
              },
              {
                component: 'ProgramTableColumn',
                title: 'Nº Semestre',
                elements: [
                  {
                    component: 'ProgramTableRow',
                    title: 'Asignatura',
                    credits: '6 ECTS',
                    type: 'Obligatoria',
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  },

  thumbnails: {
    '1x': thumbnailsStudyPlan,
    '2x': thumbnailsStudyPlan,
  },
}
