import thumbnail from '@griddo-images/thumbnails/Footer.png'
import thumbnail2x from '@griddo-images/thumbnails/Footer@2x.png'

export default {
  schemaType: 'module',
  displayName: 'Footer',
  component: 'Footer',
  type: 'footer',
  category: 'navigation',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          key: 'title',
          mandatory: true,
        },
        {
          title: 'Input placeholder',
          type: 'TextField',
          key: 'placeholder',
          mandatory: true,
        },
        {
          title: 'Claim',
          type: 'TextField',
          key: 'claim',
          mandatory: true,
        },
        {
          title: 'Message sending ok',
          type: 'TextField',
          key: 'sendingOk',
          mandatory: true,
        },
        {
          title: 'Invalid email message',
          type: 'TextField',
          key: 'invalidEmailMessage',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          type: 'UniqueCheck',
          key: 'setAsDefault',
          options: [
            {
              title: 'Set as Footer default',
            },
          ],
        },
        {
          title: 'Select Footer Menu',
          key: 'footerMenu',
          type: 'AsyncSelect',
          entity: 'menu_containers',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'Footer',
    type: 'footer',
    title: 'Footer name',
    claim: 'Lorem ipsum',
    placeholder: 'Introduce tu email...',
    sendingOk: '¡Gracias por suscribirte!',
    invalidEmailMessage: 'El correo electrónico debe tener el formato correcto',
    setAsDefault: false,
  },

  thumbnails: {
    '1x': thumbnail,
    '2x': thumbnail2x,
  },
}
