import { useContext } from 'react'
import { SiteContext } from '@contexts'

export default function useRenderer() {
  const { renderer } = useContext(SiteContext)

  return {
    isSSG: renderer === 'gatsby',
    isStorybook: renderer === 'storybook',
    // solo editor
    isEditor: renderer === 'editor',
    // solo preview
    isPreview: renderer === 'preview',
    // editor o preview
    isGriddo: renderer === 'preview' || renderer === 'editor',
  }
}
