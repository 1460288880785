import { usePage } from '@griddo/core'
import { useMemo } from 'react'

export function useGetLocale() {
  const { languageId: currentLanguageID, pageLanguages } = usePage()

  const getCurrentLocate = () => {
    if (pageLanguages?.length > 0) {
      const items = pageLanguages.filter(
        ({ languageId }) => languageId === currentLanguageID
      )
      if (items && items.length > 0)
        return items.reduce(({ locale }) => locale).locale || 'es'
    }

    return 'es'
  }

  return useMemo(() => getCurrentLocate(), [])
}
