import thumbnailsProgramList from '@griddo-images/thumbnails/ProgramList.png'

import ButtonRequestAdmission from '@schemas/components/ButtonRequestAdmission'
import NewsDistributor from '@schemas/modules/NewsDistributor'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Program List',
  component: 'ProgramList',
  dataPacks: ['PROGRAMS'],
  type: { label: 'Programs', value: 'programs', mode: 'list' },
  content: [
    {
      title: 'Use image as background',
      type: 'ConditionalField',
      key: 'conditionalBackground',
      mandatory: true,
      options: [
        {
          value: true,
          title: 'Yes',
          name: 'yes',
        },
        {
          value: false,
          title: 'No',
          name: 'no',
        },
      ],
      fields: [
        {
          title: 'Background Image',
          type: 'ComponentContainer',
          whiteList: ['BackgroundImage'],
          key: 'backgroundImage',
          condition: true,
          helptext: 'Recommended size: 1920x1080',
        },
      ],
    },
    {
      title: 'Icon',
      type: 'ImageField',
      key: 'icon',
      hideable: true,
    },
    {
      title: 'Program Title',
      type: 'HeadingField',
      key: 'programTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
    },
    {
      title: 'Description',
      type: 'RichText',
      key: 'description',
      hideable: true,
      html: true,
    },
    {
      title: '',
      type: 'ReferenceField',
      sources: [{structuredData: 'PROGRAMS' }],
      key: 'data',
    },
    {
      title: 'Items per page',
      type: 'NumberField',
      key: 'itemsPerPage',
      mandatory: true,
    },
    {
      title: 'No result text',
      type: 'TextField',
      key: 'noResultText',
      mandatory: true,
    },
    {
      title: 'Introduction text',
      type: 'TextField',
      key: 'introductionText',
      hideable: true,
    },
    {
      title: 'Select template filters',
      type: 'CheckGroup',
      key: 'filterList',
      options: [
        {
          value: 'programType',
          title: 'Show Program Type filter',
          name: 'programType',
        },
        {
          value: 'area',
          title: 'Show Area filter',
          name: 'area',
        },
        {
          value: 'language',
          title: 'Show Language filter',
          name: 'language',
        },
        {
          value: 'mode',
          title: 'Show Mode filter',
          name: 'mode',
        },
      ],
    },
    {
      title: 'Decoration',
      type: 'ConditionalField',
      key: 'line',
      options: [
        {
          value: true,
          title: 'Line',
          name: 'yes',
        },
        {
          value: false,
          title: 'No Line',
          name: 'no',
        },
      ],
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: [
        'AccordionCollection',
        'BasicContent',
        'CardCollection',
        'CypherCollection',
        'DownloadCollection',
        'MultimediaContent',
        'ColumnText',
        'BasicText',
        'Spacer',
        'StudyPlan',
        'Testimonials',
        'HighlightedContent',
        'FeaturedBlock',
        'FeaturedAudiovisual',
        'GoToTop',
        'HorizontalTabs',
        'CarouselCollection',
        'VerticalTabs',
        'MixedCollection',
        'ColumnsPresentation',
        'ProgramsDistributor',
        'Grid',
        'LogoCollection',
        'SocialShare',
        'StaffDistributor',
        'NewsDistributor',
      ],
      key: 'relatedContent',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo', 'ButtonRequestAdmission'],
      key: 'moreInfo',
      maxItems: 2,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ProgramList',
    conditionalBackground: false,
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 60,
    },
    icon: defaultBackgroundImage,
    programTitle: { tag: 'h1', content: 'Lista de Programas' },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{structuredData: 'PROGRAMS' }],
      order: 'recent',
      fullRelations: true,
      quantity: 0,
    },
    itemsPerPage: 10,
    noResultText:
      'Lo sentimos, no hemos encontrado resultados. Por favor, intenta una nueva búsqueda.',
    introductionText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    filterList: [],
    line: true,
    relatedContent: {
      component: 'Section',
      name: 'Related Content',
      modules: [{ ...NewsDistributor.default }],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [
        { ...BasicMoreInfo.default },
        { ...ButtonRequestAdmission.default },
      ],
    },
  },

  thumbnails: {
    '1x': thumbnailsProgramList,
    '2x': thumbnailsProgramList,
  },
}
