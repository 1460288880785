export default {
  schemaType: 'component',
  displayName: 'Program Tag',
  component: 'ProgramTag',
  dataPacks: ['PROGRAMS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Tag',
          type: 'TextField',
          key: 'title',
        },
      ],
    },
  ],

  default: {
    component: 'ProgramTag',
    title: 'Últimas plazas',
  },
}
