import thumbnailsStaffDetail from '@griddo-images/thumbnails/StaffDetail.png'

import SocialShare from '@schemas/modules/SocialShare'
import StaffDistributor from '@schemas/modules/StaffDistributor'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { basicModuleWhiteList } from '@schemas/modules'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Staff Detail',
  component: 'StaffDetail',
  dataPacks: ['STAFF'],
  type: { label: 'Staff', value: 'staff', mode: 'detail' },
  content: [
    {
      title: 'Staff Photo',
      type: 'ImageField',
      key: 'staffPhoto',
      mandatory: true,
      helptext: 'Recommended image size: 1296x626',
    },
    {
      type: 'SliderField',
      step: 1,
      minvalue: 0,
      maxvalue: 100,
      key: 'staffPhotoY',
      title: 'Vertical shift',
    },
    {
      type: 'SliderField',
      step: 1,
      minvalue: 0,
      maxvalue: 100,
      key: 'staffPhotoX',
      title: 'Horizontal shift',
    },
    {
      title: 'Staff Name',
      type: 'HeadingField',
      key: 'staffTitle',
      default: { tag: 'h1', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
      helptext: 'Maximum 80 characters',
    },
    {
      title: 'Summary',
      type: 'RichText',
      key: 'summary',
      mandatory: true,
      helptext: 'Maximum 180 characters',
      html: true,
    },
    {
      title: 'Staff Type',
      type: 'AsyncCheckGroup',
      source: 'STAFF_TYPE',
      key: 'staffTypeRelation',
      mandatory: true,
    },
    {
      title: 'Staff Position',
      type: 'TextField',
      key: 'staffPosition',
      hideable: true,
    },

    {
      title: 'Title 1',
      type: 'TextField',
      key: 'professionalExperienceTitle',
      hideable: true,
    },
    {
      title: 'Text 1',
      type: 'RichText',
      key: 'professionalExperience',
      hideable: true,
      html: true,
    },
    {
      title: 'Title 2',
      type: 'TextField',
      key: 'teachingExperienceTitle',
      hideable: true,
    },
    {
      title: 'Text 2',
      type: 'RichText',
      key: 'teachingExperience',
      hideable: true,
      html: true,
    },
    {
      title: 'Title 3',
      type: 'TextField',
      key: 'subjectsTitle',
      hideable: true,
    },
    {
      title: 'Text 3',
      type: 'RichText',
      key: 'subjects',
      hideable: true,
      html: true,
    },
    {
      title: 'Quote',
      type: 'TextField',
      key: 'quote',
      hideable: true,
    },
    {
      title: 'Linkedin',
      type: 'TextField',
      key: 'linkedin',
      hideable: true,
    },
    {
      type: 'UniqueCheck',
      key: 'isLinkedinUrlVisible',
      options: [
        {
          title: 'Show Linkedin URL',
        },
      ],
    },
    {
      title: 'Email',
      type: 'TextField',
      key: 'email',
      hideable: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: basicModuleWhiteList,
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'StaffDetail',
    staffTitle: { content: 'Title', tag: 'h1' },
    summary:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
    // staffType: 'Staff type',
    staffPosition: 'Staff Position',
    staffPhotoX: 50,
    staffPhotoY: 50,
    professionalExperienceTitle: 'Titulación',
    professionalExperience: 'Titulación descripción...',
    teachingExperienceTitle: 'Experiencia profesional',
    teachingExperience: 'Experiencia profesional descripción...',
    subjectsTitle: 'Asignaturas que imparte',
    subjects: 'Asignaturas que imparte descripción...',
    email: 'email@email.com',
    linkedin: 'linkedin.com/...',
    isLinkedinUrlVisible: true,
    quote: '"This is a very important quote"',
    staffPhoto: defaultBackgroundImage,
    staffTypeRelation: [],
    mainSection: {
      component: 'Section',
      name: 'Related Content',
      modules: [{ ...StaffDistributor.default }, { ...SocialShare.default }],
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsStaffDetail,
    '2x': thumbnailsStaffDetail,
  },
}
