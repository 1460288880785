import { useEffect, useState } from 'react'
import { getWindow } from 'ssr-window'

const window = getWindow()

function mqListener(mql, handler) {
  return mql.addEventListener('change', handler)
}

export default function useMediaQuery(mediaQuery) {
  const [isVerified, setIsVerified] = useState(false)

  useEffect(() => {
    setIsVerified(!!window.matchMedia(mediaQuery).matches)
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)
    mqListener(mediaQueryList, documentChangeHandler)
    documentChangeHandler()

    return () => {
      mqListener(mediaQueryList, documentChangeHandler)
    }
  }, [mediaQuery])

  return isVerified
}
