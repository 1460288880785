import thumbnailsHeroCompact from '@griddo-images/thumbnails/HeroCompact.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslight from '@griddo-images/themes/light.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Hero Compact',
  component: 'HeroCompact',
  dataPacks: null,
  category: 'hero',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Use image as background',
          type: 'ConditionalField',
          key: 'conditionalBackground',
          mandatory: true,
          options: [
            {
              value: true,
              title: 'Yes',
              name: 'yes',
            },
            {
              value: false,
              title: 'No',
              name: 'no',
            },
          ],
          fields: [
            {
              title: 'Background Image',
              type: 'ComponentContainer',
              whiteList: ['BackgroundImage'],
              key: 'backgroundImage',
              condition: true,
              helptext: 'Recommended size: 1920x1080',
            },
          ],
        },
        {
          title: 'Pre-title',
          type: 'TextField',
          key: 'preTitle',
          hideable: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: false,
        },
        {
          title: 'Description',
          type: 'TextField',
          key: 'description',
          hideable: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'FrontImage',
          type: 'ImageField',
          key: 'frontImage',
          hideable: true,
          helptext: 'Recommended size: 538x616',
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'blue',
              img: themesblue,
            },
          ],
          columns: 8,
        },
        {
          title: 'Decoration',
          type: 'ConditionalField',
          key: 'line',
          options: [
            {
              value: true,
              title: 'Line',
              name: 'yes',
            },
            {
              value: false,
              title: 'No Line',
              name: 'no',
            },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'HeroCompact',
    conditionalBackground: false,
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 60,
    },
    preTitle: 'Text',
    title: {
      content: 'Text',
      tag: 'h2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    theme: 'blue',
    line: true,
  },

  thumbnails: {
    '1x': thumbnailsHeroCompact,
    '2x': thumbnailsHeroCompact,
  },
}
