export default {
  schemaType: 'component',
  displayName: 'Program Quick Fact',
  component: 'LandingQuickFact',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          mandatory: true,
        },
        {
          title: 'Title',
          type: 'TextField',
          key: 'title',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'LandingQuickFact',
    icon: {
      alt: '',
      publicId: 'placeholders/griddo-background',
    },
    title: 'Title',
  },
}
