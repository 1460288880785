import thumbnailsVerticalTabs from '@griddo-images/thumbnails/VerticalTabs.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Vertical Tabs',
  component: 'VerticalTabs',
  dataPacks: null,
  category: 'interactive',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: false,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
          mandatory: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Cards',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          key: 'elements',
          whiteList: ['VerticalTabElement'],
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Anchor ID',
          type: 'TextField',
          key: 'anchorID',
          prefix: '#',
          hideable: true,
          helptext:
            'Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.',
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    nesting: false,
    component: 'VerticalTabs',
    title: { content: 'Text', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    detail:
      'Aenean a congue felis. Duis imperdiet urna sit amet odio lacinia faucibus. Suspendisse varius mauris nec tempor euismod. Quisque pharetra lorem sed sem tempor, accumsan consectetur leo varius. Aliquam at augue viverra, convallis ante vitae, tempor ex. Nunc elit elit, volutpat quis augue ut, malesuada auctor ante. Sed a rhoncus nibh, eleifend pellentesque lacus. Sed semper erat arcu, in tempor purus accumsan eu. Aliquam venenatis justo lacus, at mollis nisl venenatis id. Proin eget mattis tortor. Donec auctor justo quis auctor porta. Donec eget elit sollicitudin, congue urna eget, tristique sapien. Mauris lobortis enim id sapien convallis euismod.',
    kind: 'VerticalTabElement',
    elements: [
      {
        component: 'VerticalTabElement',
        image: defaultBackgroundImage,
        title: { content: 'TAB 1', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'VerticalTabElement',
        image: defaultBackgroundImage,
        title: { content: 'TAB 2', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'VerticalTabElement',
        image: defaultBackgroundImage,
        title: { content: 'TAB 3', tag: 'h2' },
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'link',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
    theme: 'ultraLightBlue',
  },
  thumbnails: {
    '1x': thumbnailsVerticalTabs,
    '2x': thumbnailsVerticalTabs,
  },
}
