import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Testimonial Card',
  component: 'TestimonialCard',
  dataPacks: ['TESTIMONIALS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          key: 'title',
          hideable: true,
        },
        {
          title: 'Name',
          type: 'TextField',
          key: 'name',
          hideable: true,
        },
        {
          title: 'Quote',
          type: 'TextField',
          key: 'quote',
          hideable: true,
        },
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          hideable: true,
          helptext: 'Recommended size: 240x290',
        },
      ],
    },
  ],

  default: {
    component: 'TestimonialCard',
    title: 'Lorem ipsum',
    name: 'Lorem ipsum',
    quote: 'Lorem ipsum',
    image: defaultBackgroundImage,
  },
}
