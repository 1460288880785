import thumbnailsAccordionCollection from '@griddo-images/thumbnails/AccordionCollection.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'FAQ Collection',
  component: 'FAQCollection',
  category: 'interactive',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h1', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Lorem Ipsum' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Accordion',
          type: 'ComponentArray',
          whiteList: ['AccordionCollection'],
          elementUniqueSelection: true,
          key: 'elements',
          maxItems: null,
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'FAQCollection',
    title: null,
    subtitle: null,
    detail: null,
    kind: 'AccordionCollection',
    elements: [
      {
        component: 'AccordionCollection',
        nesting: false,
        isFAQ: false,
        icon: defaultBackgroundImage,
        title: {
          content: 'Title',
          tag: 'h2',
        },
        subtitle: {
          content: 'Subtitle',
          tag: 'h2',
        },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis.',
        kind: 'AccordionElement',
        elements: [
          {
            component: 'AccordionElement',
            title: {
              content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              tag: 'h2',
            },
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum augue euismod nisi imperdiet condimentum. Pellentesque fringilla eleifend justo, eget sagittis leo auctor eu. Duis at ligula leo. Nam sagittis tristique dolor ac pharetra. Cras venenatis tellus leo, eget facilisis metus imperdiet non. Morbi quis efficitur ante. Vestibulum quis dictum ligula. Etiam ultricies maximus augue, sed pharetra tellus luctus a. Pellentesque vestibulum tempor leo, et congue quam vestibulum sed.',
          },
          {
            component: 'AccordionElement',
            title: {
              content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              tag: 'h2',
            },
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum augue euismod nisi imperdiet condimentum. Pellentesque fringilla eleifend justo, eget sagittis leo auctor eu. Duis at ligula leo. Nam sagittis tristique dolor ac pharetra. Cras venenatis tellus leo, eget facilisis metus imperdiet non. Morbi quis efficitur ante. Vestibulum quis dictum ligula. Etiam ultricies maximus augue, sed pharetra tellus luctus a. Pellentesque vestibulum tempor leo, et congue quam vestibulum sed.',
          },
        ],
      },
      {
        component: 'AccordionCollection',
        nesting: false,
        isFAQ: false,
        icon: defaultBackgroundImage,
        title: {
          content: 'Title',
          tag: 'h2',
        },
        subtitle: {
          content: 'Subtitle',
          tag: 'h2',
        },
        detail:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis.',
        kind: 'AccordionElement',
        elements: [
          {
            component: 'AccordionElement',
            title: {
              content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              tag: 'h2',
            },
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum augue euismod nisi imperdiet condimentum. Pellentesque fringilla eleifend justo, eget sagittis leo auctor eu. Duis at ligula leo. Nam sagittis tristique dolor ac pharetra. Cras venenatis tellus leo, eget facilisis metus imperdiet non. Morbi quis efficitur ante. Vestibulum quis dictum ligula. Etiam ultricies maximus augue, sed pharetra tellus luctus a. Pellentesque vestibulum tempor leo, et congue quam vestibulum sed.',
          },
          {
            component: 'AccordionElement',
            title: {
              content:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              tag: 'h2',
            },
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum augue euismod nisi imperdiet condimentum. Pellentesque fringilla eleifend justo, eget sagittis leo auctor eu. Duis at ligula leo. Nam sagittis tristique dolor ac pharetra. Cras venenatis tellus leo, eget facilisis metus imperdiet non. Morbi quis efficitur ante. Vestibulum quis dictum ligula. Etiam ultricies maximus augue, sed pharetra tellus luctus a. Pellentesque vestibulum tempor leo, et congue quam vestibulum sed.',
          },
        ],
      },
    ],
  },

  thumbnails: {
    '1x': thumbnailsAccordionCollection,
    '2x': thumbnailsAccordionCollection,
  },
}
