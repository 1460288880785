// Gatsby ssr api
import * as React from 'react'

//const griddoWebfonts = (
//  <link
//    rel='preload'
//    href='/webfonts.css'
//    as='style'
//    // This id is required in order to usen it later in the `builder.browser.js`
//    id='griddo-webfonts'
//    crossOrigin
//    // This onload only works on Griddo Editor (AX)
//    // The code to trigger the onload for the build phase (CX) is th builder.browser.js
//    onLoad="this.onload=null;this.rel='stylesheet'"
//  />
//)

//const griddoWebfonts = (
//  <link rel='stylesheet' href='/webfonts.1.css' as='style' id='griddo-webfonts' />
//)

//const griddoWebfonts = (
//  <style key="griddo-webfonts">
//    {`@font-face{font-family:Cooper;font-weight:900;font-style:normal;src:url('/fonts/cooper/Cooper-Bold.woff2') format('woff2');font-display:swap}@font-face{font-family:Cooper;font-weight:400;font-style:italic;src:url('/fonts/cooper/Cooper-LightItalic.woff2') format('woff2');font-display:optional}@font-face{font-family:Graphik;font-weight:100;font-style:normal;src:url('/fonts/graphik/Graphik-Extralight.woff2') format('woff2');font-display:optional}@font-face{font-family:Graphik;font-weight:200;font-style:normal;src:url('/fonts/graphik/Graphik-Light.woff2') format('woff2');font-display:optional}@font-face{font-family:Graphik;font-weight:200;font-style:italic;src:url('/fonts/graphik/Graphik-LightItalic.woff2') format('woff2');font-display:optional}@font-face{font-family:Graphik;font-weight:400;font-style:normal;src:url('/fonts/graphik/Graphik-Regular.woff2') format('woff2');font-display:optional}@font-face{font-family:Graphik;font-weight:500;font-style:normal;src:url('/fonts/graphik/Graphik-Semibold.woff2') format('woff2');font-display:optional}@font-face{font-family:GraphikMedium;font-weight:500;font-style:normal;src:url('/fonts/graphik/Graphik-Medium.woff2') format('woff2');font-display:optional}@font-face{font-family:Cooper;font-weight:400;font-style:normal;src:url('/fonts/cooper/Cooper-Light.woff2') format('woff2');size-adjust:99%;ascent-override:normal;descent-override:normal;line-gap-override:normal;font-display:swap}@font-face{font-family:Cooper;font-weight:900;font-style:italic;src:url('/fonts/cooper/Cooper-BoldItalic.woff2') format('woff2');font-display:optional}` }
//  </style>
//)
const griddoWebfontsStr = `@font-face{font-family:Cooper;font-weight:900;font-style:normal;src:url(/fonts/cooper/Cooper-Bold.woff2) format(woff2);font-display:swap}@font-face{font-family:Cooper;font-weight:400;font-style:italic;src:url(/fonts/cooper/Cooper-LightItalic.woff2) format(woff2);font-display:optional}@font-face{font-family:Graphik;font-weight:100;font-style:normal;src:url(/fonts/graphik/Graphik-Extralight.woff2) format(woff2);font-display:optional}@font-face{font-family:Graphik;font-weight:200;font-style:normal;src:url(/fonts/graphik/Graphik-Light.woff2) format(woff2);font-display:optional}@font-face{font-family:Graphik;font-weight:200;font-style:italic;src:url(/fonts/graphik/Graphik-LightItalic.woff2) format(woff2);font-display:optional}@font-face{font-family:Graphik;font-weight:400;font-style:normal;src:url(/fonts/graphik/Graphik-Regular.woff2) format(woff2);font-display:optional}@font-face{font-family:Graphik;font-weight:500;font-style:normal;src:url(/fonts/graphik/Graphik-Semibold.woff2) format(woff2);font-display:optional}@font-face{font-family:GraphikMedium;font-weight:500;font-style:normal;src:url(/fonts/graphik/Graphik-Medium.woff2) format(woff2);font-display:optional}@font-face{font-family:Cooper;font-weight:400;font-style:normal;src:url(/fonts/cooper/Cooper-Light.woff2) format(woff2);size-adjust:99%;ascent-override:normal;descent-override:normal;line-gap-override:normal;font-display:swap}@font-face{font-family:Cooper;font-weight:900;font-style:italic;src:url(/fonts/cooper/Cooper-BoldItalic.woff2) format(woff2);font-display:optional}`
const griddoWebfonts = (
  <style key="griddo-webfonts">{griddoWebfontsStr}</style>
)

const preconnectGTM = (
  <link rel='preconnect' href='//www.googletagmanager.com' />
)

const prefetchGTM = (
  <link rel='dns-prefetch' href='https://www.googletagmanager.com/' />
)

const preconnectDAMPRE = (
  <link
    rel='preconnect'
    href='https://images.pre.griddo.universitatcarlemany.com/'
  />
)

const prefetchDAMPRE = (
  <link
    rel='dns-prefetch'
    href='https://images.pre.griddo.universitatcarlemany.com/'
  />
)

const preconnectDAM = (
  <link
    rel='preconnect'
    href='https://images.griddo.universitatcarlemany.com/'
  />
)

const prefetchDAM = (
  <link
    rel='dns-prefetch'
    href='https://images.griddo.universitatcarlemany.com/'
  />
)

const preconnectCookie = (
  <link rel='dns-prefetch' href='https://cdn.cookielaw.org' />
)


const prefetchCookie = (
  <link rel='preconnect' href='https://cdn.cookielaw.org' />
)

const preconnectAssets = (
  <link
    rel='preconnect'
    href='https://assets.griddo.universitatcarlemany.com/'
  />
)

const prefetchAssets = (
  <link
    rel='dns-prefetch'
    href='https://assets.griddo.universitatcarlemany.com/'
  />
)

const prefetchAssetsPRE = (
  <link rel='dns-prefetch' href='https://assets.pre.griddo.universitatcarlemany.com/' />
)
const preconnectAssetsPRE = (
  <link rel='preconnect' href='https://assets.pre.griddo.universitatcarlemany.com/' />
)

//const preloadFontGraphikPRE = (
//  <link
//    rel="preload"
//    href="/fonts/graphik/Graphik-Regular.woff2"
//    as="font"
//    type="font/woff2"
//    crossOrigin
//  />
//)
//
//const preloadFontGraphikLightPRE = (
//  <link
//    rel="preload"
//    href="/fonts/graphik/Graphik-Semibold.woff2"
//    as="font"
//    type="font/woff2"
//    crossOrigin
//  />
//)
//
//const preloadFontGraphikMediumPRE = (
//  <link
//    rel="preload"
//    href="/fonts/graphik/Graphik-Medium.woff2"
//    as="font"
//    type="font/woff2"
//    crossOrigin
//  />
//)
//const preloadFontCooperLightPRE = (
//  <link
//    rel="preload"
//    href="/fonts/cooper/Cooper-Light.woff2"
//    as="font"
//    type="font/woff2"
//    crossOrigin
//  />
//)
//
//const preloadFontCooperLightItalicPRE = (
//  <link
//    rel="preload"
//    href="/fonts/cooper/Cooper-LightItalic.woff2"
//    as="font"
//    type="font/woff2"
//    crossOrigin
//  />
//)

const cookiepro = (
  <script
    async
    key='cookiepro'
    src='https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js'
    data-document-language='true'
    type='text/javascript'
    charset='UTF-8'
    data-domain-script='30b33bea-875b-40bb-ab5a-2adced9f017f'
  />
)

const cookieproFunction = (
  <script
    dangerouslySetInnerHTML={{
      __html: `function OptanonWrapper() {}`,
    }}
  />
)

const cookieproShowBannerFunction = (
  <script
    dangerouslySetInnerHTML={{
      __html: `function showBannerCP(){document.getElementById("onetrust-consent-sdk").style.display="block",window.removeEventListener("touchstart",showBannerCP),window.removeEventListener("scroll",showBannerCP),window.removeEventListener("click",showBannerCP),window.removeEventListener("mousemove",showBannerCP)}window.innerWidth<=800&&document.addEventListener("DOMNodeInserted",(function(e){"onetrust-consent-sdk"==e.target.id&&(document.getElementById("onetrust-consent-sdk").style.display="none",window.addEventListener("touchstart",showBannerCP),window.addEventListener("scroll",showBannerCP),window.addEventListener("click",showBannerCP),window.addEventListener("mousemove",showBannerCP),setTimeout((function(){showBannerCP()}),7e3))}),!1);`,
    }}
  />
)

// onRenderBody hook
function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
  setBodyAttributes({ id: 'ucma-site' })
}

function onPreRenderHTML({
  getHeadComponents,
  replaceHeadComponents,
  getPostBodyComponents,
  replacePostBodyComponents,
}) {
  const headComponents = getHeadComponents()
  const postBodyComponents = getPostBodyComponents()

  let _headComponents = [
    preconnectGTM,
    prefetchGTM,
    preconnectCookie,
    prefetchCookie,
    cookiepro,
    cookieproFunction,
    cookieproShowBannerFunction,
  ]

  if (process.env.CLIENT === 'UCMA') {
    _headComponents = [
      preconnectGTM,
      prefetchGTM,
      preconnectDAM,
      prefetchDAM,
      preconnectAssets,
      prefetchAssets,
      preconnectCookie,
      prefetchCookie,
      cookiepro,
      cookieproFunction,
      cookieproShowBannerFunction,
    ]
  }

  if (process.env.CLIENT === 'UCMA-pre') {
    _headComponents = [
      preconnectGTM,
      prefetchGTM,
      preconnectDAMPRE,
      prefetchDAMPRE,
      prefetchAssetsPRE,
      preconnectCookie,
      prefetchCookie,
      cookiepro,
      cookieproFunction,
      cookieproShowBannerFunction,
    ]
  }

  const allComponents = [
    griddoWebfonts,
    _headComponents,
    ...postBodyComponents,
    ...headComponents,
  ]

  replaceHeadComponents(allComponents)
  replacePostBodyComponents([])
}

export default {
  onRenderBody,
  onPreRenderHTML,
}
