// import thumbnailsImageCard2x from '@griddo-images/thumbnails/ImageCard@2x.png';
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Image Card',
  component: 'ImageCard',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Image',
          type: 'ImageField',
          key: 'image',
          hideable: false,
          helptext: 'Recommended size: 360x214',
        },
        {
          title: 'Type',
          type: 'RadioGroup',
          key: 'type',
          options: [
            {
              title: 'Otros',
              name: 'otros',
              value: 'other',
            },
            {
              title: 'Eventos',
              name: 'eventos',
              value: 'events',
            },
            {
              title: 'Programas',
              name: 'programas',
              value: 'programs',
            },
            {
              title: 'Noticias',
              name: 'noticias',
              value: 'news',
            },
          ],
          hideable: false,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'URL',
          type: 'UrlField',
          key: 'url',
          advanced: true,
          mandatory: false,
          hedeable: false,
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Anchor ID',
          type: 'TextField',
          key: 'anchorID',
          prefix: '#',
          hideable: true,
          helptext:
            'Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.',
        },
      ],
    },
  ],

  default: {
    component: 'ImageCard',
    title: {
      content: 'Lorem ipsum',
      tag: 'h2',
    },
    image: defaultBackgroundImage,
    type: 'other',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    url: {
      href: '',
      linkTo: null,
      newTab: false,
      noFollow: false,
    },
  },
  thumbnails: {
    // '2x': thumbnailsImageCard2x,
  },
}
