import { useEffect, useState } from 'react'

import { getCurrentDateYYYYMMDD_HHMMSS } from '@utils/CurrentDate'
import { useLocalStorage } from '@hooks/index'
import { useGetLocale } from '@hooks/useGetLocale'

const splitTextLocale = (locale) => {
  const string = locale.split('_')
  return string[0] || locale
}

export default function useLegalText(publicApiUrl) {
  const [legalText, setLegalText] = useState({})
  const [legalHeaderText, setLegalHeaderText] = useState('')
  const [type, setType] = useState()

  const [localStorageExpired, setLocalStorageExpired] = useLocalStorage(
    'legalTextExpired'
  )
  const [idClausula, setIdClausula] = useLocalStorage('idClausula')
  const [idClausulaPie, setIdClausulaPie] = useLocalStorage('idClausulaPie')
  const [cabeceraTexto, setCabeceraTexto] = useLocalStorage('cabeceraTexto')
  const [contenidoTexto1, setContenidoTexto1] = useLocalStorage(
    'contenidoTexto1'
  )
  const [contenidoTexto2, setContenidoTexto2] = useLocalStorage(
    'contenidoTexto2'
  )
  const [pieTexto, setPieTexto] = useLocalStorage('pieTexto')
  const [processType, setProcessType] = useLocalStorage('process_type')

  const now = new Date()

  useEffect(() => {
    if (localStorageExpired.length === 0)
      setLocalStorageExpired(now.setTime(now.getTime() + 24 * 60 * 60 * 1000))
  }, [localStorageExpired])

  const timeLocalStorageExpired = now.setTime(now.getTime())

  const locale = useGetLocale(),
    lang = splitTextLocale(locale)

  useEffect(() => setProcessType(type), [type])

  useEffect(() => {
    import('@xmldom/xmldom').then(({ DOMParser }) => {
      setTimeout(() => {
        if (!!type) {
          if (
            (cabeceraTexto?.length === 0 ||
              parseInt(localStorageExpired) <= timeLocalStorageExpired ||
              processType !== type) &&
            publicApiUrl
          ) {
            import('@utils/FormLegalText').then(({ getLegalText }) => {
              getLegalText({ publicApiUrl, lang, type })
                .then(({ data: resp, status }) => {
                  if (status === 200) {
                    const parser = new DOMParser()
                    const xmlDoc = parser.parseFromString(resp, 'text/xml')

                    setIdClausula(
                      xmlDoc.getElementsByTagName('idClausula')[0]
                        ?.childNodes[0]?.nodeValue || ''
                    )
                    setIdClausulaPie(
                      xmlDoc.getElementsByTagName('idClausulaPie')[0]
                        ?.childNodes[0]?.nodeValue || ''
                    )
                    setCabeceraTexto(
                      '<div>' +
                        xmlDoc.getElementsByTagName('cabeceraTexto')[0]
                          ?.childNodes[0]?.nodeValue || '' + '</div>'
                    )

                    setPieTexto(
                      xmlDoc.getElementsByTagName('pieTexto')[0]?.childNodes[0]
                        ?.nodeValue || ''
                    )
                    const ct = xmlDoc.getElementsByTagName('contenidoTexto')[0]
                      ?.childNodes[0]?.nodeValue

                    if (ct && ct.length > 0) {
                      const ctSplit = ct.split('[E]')
                      if (ctSplit.length > 0) {
                        const checkTexts = ctSplit[1].split('[G]')
                        setContenidoTexto1('<div>' + checkTexts[0].slice(0, -5))
                        setContenidoTexto2('<div>' + checkTexts[1])
                      }
                    }
                  }
                })
                .catch((error) => {
                  console.log(error)
                })
            })
          }
        }
        setLegalText({ idClausula, idClausulaPie, cabeceraTexto })
      }, 300)
    })
  }, [cabeceraTexto, processType])

  useEffect(() => setLegalHeaderText(legalText?.cabeceraTexto), [legalText])

  return [
    legalText,
    legalHeaderText,
    contenidoTexto1,
    contenidoTexto2,
    pieTexto,
    setType,
  ]
}

function useMountDataSTLOPD() {
  const [checks, setChecks] = useState({})
  const [dataSTLOPD, setDataSTLOPD] = useState([])
  const [sendSTLOPD, setSendSTLOPD] = useState(false)

  const locale = useGetLocale(),
    lang = splitTextLocale(locale)

  const extraData = [],
    storageKeys = [
      'correoE_Usuario',
      'DNI_Usuario',
      'Direccion_Usuario',
      'Telefono_Usuario',
      'IP_Usuario',
      'Id_Externo',
    ],
    dates = ['FGrabacion', 'FAceptacion']

  // const { publicApiUrl } = useSite()

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (
  //       (typeof window !== 'undefined' &&
  //         !window.sessionStorage.getItem('IP_Usuario')) ||
  //       !window.sessionStorage.getItem('country')
  //     ) {
  //       const ip = getUserIP({ publicApiUrl })
  //     }
  //   }, 500)
  // }, [])

  useEffect(() => {
    if (checks?.checkP1 >= 0 || checks?.checkP2 >= 0) {
      extraData.push(
        `<id>${
          new Date().getTime() + (Math.random() + 1).toString(36).substring(7)
        }</id>`
      )

      if (typeof window !== 'undefined') {
        storageKeys.map((key) => {
          const value = window.sessionStorage?.getItem(key)
          if (value?.length > 1)
            extraData.push(`<${key}>${value.replaceAll('"', '')}</${key}>`)
        })

        extraData.push(
          `<idClausula>${window.localStorage?.getItem(
            'idClausula'
          )}</idClausula>`
        )
        extraData.push(
          `<idClausulaPie>${window.localStorage?.getItem(
            'idClausulaPie'
          )}</idClausulaPie>`
        )
      }

      dates.map((key) =>
        extraData.push(`<${key}>${getCurrentDateYYYYMMDD_HHMMSS()}</${key}>`)
      )
      Object.keys(checks).forEach((tag) =>
        extraData.push(`<${tag}>${checks[tag]}</${tag}>`)
      )

      setDataSTLOPD(extraData)
    }
  }, [checks])

  useEffect(() => setSendSTLOPD(false), [sendSTLOPD])

  return [dataSTLOPD, setChecks, sendSTLOPD, setSendSTLOPD, lang]
}

export { useMountDataSTLOPD }
