import buttonslink from '@griddo-images/buttons/link.png'
import buttonssecondary from '@griddo-images/buttons/secondary.png'
import buttonsprimary02 from '@griddo-images/buttons/primary02.png'
import buttonsprimary01 from '@griddo-images/buttons/primary01.png'

export default {
  schemaType: 'component',
  displayName: 'Request Admission',
  component: 'ButtonRequestAdmission',
  dataPacks: null,
  category: 'content',
  configTabs: [
    {
      title: 'content',
      fields: [
        { title: 'Text', type: 'TextField', key: 'text' },
        { title: 'URL', type: 'UrlField', key: 'url', advanced: true },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Appearance',
          key: 'appearance',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'primary01',
              img: buttonsprimary01,
            },
            {
              value: 'primary02',
              img: buttonsprimary02,
            },
            {
              value: 'secondary',
              img: buttonssecondary,
            },
            {
              value: 'link',
              img: buttonslink,
            },
          ],
          columns: 4,
        },
      ],
    },
  ],

  default: {
    component: 'ButtonRequestAdmission',
    text: 'SOLICITA ADMISIÓN',
    url: {
      href: '',
      linkToURL: null,
      newTab: false,
      noFollow: false,
    },
  },
}
