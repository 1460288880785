import thumbnailsBasicText from '@griddo-images/thumbnails/BasicText.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themeslight from '@griddo-images/themes/light.png'
import layoutsBasicTextlayout002 from '@griddo-images/layouts/BasicText/layout002.png'
import layoutsBasicTextlayout001 from '@griddo-images/layouts/BasicText/layout001.png'

export default {
  schemaType: 'module',
  displayName: 'Basic Text',
  component: 'BasicText',
  dataPacks: null,
  category: 'content',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: true,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'image',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Entry',
          type: 'TextField',
          key: 'entry',
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'content',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'L001',
              img: layoutsBasicTextlayout001,
            },
            {
              value: 'L002',
              img: layoutsBasicTextlayout002,
            },
          ],
          columns: 2,
        },
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'BasicText',
    nesting: false,
    title: {
      content: 'Text',
      tag: 'h2',
    },
    subtitle: {
      content: 'Text',
      tag: 'h2',
    },
    entry:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing. Fermentum eu nulla sit at aliquam. Et, est lacus, ultricies condimentum habitant scelerisque arcu magna ultrices. Curabitur sed aliquam felis ac nisi, integer sed facilisis. Ligula vel, tincidunt natoque consequat consectetur. Elementum morbi mattis auctor cursus massa eu et. Enim pellentesque consequat commodo, facilisis id maecenas. Congue quis ullamcorper massa morbi at nullam sit nunc. Consectetur volutpat volutpat et, velit. Amet nibh sed lacus, massa mattis. Nisi leo ut lacus, vel lacus ut. Varius sem egestas augue suscipit consectetur.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    layout: 'L001',
    theme: 'light',
  },

  thumbnails: {
    '1x': thumbnailsBasicText,
    '2x': thumbnailsBasicText,
  },
}
