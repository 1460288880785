export default {
  schemaType: 'component',
  displayName: 'Program Accordion Element',
  component: 'ProgramAccordionElement',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          mandatory: true,
        },
        {
          title: 'Credits',
          type: 'TextField',
          key: 'credits',
          hideable: true,
        },
        {
          title: 'Program Table',
          type: 'ComponentContainer',
          whiteList: ['ProgramTable'],
          key: 'programTable',
          mandatory: true,
          maxItems: null,
        },
      ],
    },
  ],

  default: {
    component: 'ProgramAccordionElement',
    title: { content: 'Primer Curso', tag: 'h2' },
    credits: '60 ECTS',
    programTable: {
      component: 'ProgramTable',
      elements: [
        {
          component: 'ProgramTableColumn',
          title: 'Nº Semestre',
          elements: [
            {
              component: 'ProgramTableRow',
              title: 'Asignatura',
              credits: '6 ECTS',
              type: 'Obligatoria',
            },
          ],
        },
        {
          component: 'ProgramTableColumn',
          title: 'Nº Semestre',
          elements: [
            {
              component: 'ProgramTableRow',
              title: 'Asignatura',
              credits: '6 ECTS',
              type: 'Obligatoria',
            },
          ],
        },
      ],
    },
  },
}
