import thumbnail from '@griddo-images/thumbnails/LandingHeader.png'
import thumbnail2x from '@griddo-images/thumbnails/LandingHeader@2x.png'

export default {
  schemaType: 'module',
  displayName: 'LandingNavigation',
  component: 'LandingHeader',
  type: 'header',
  category: 'navigation',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Name',
          type: 'TextField',
          key: 'title',
          mandatory: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          type: 'UniqueCheck',
          key: 'setAsDefault',
          options: [{ title: 'Set as Header default' }],
        },
        {
          title: 'Select Main Menu',
          key: 'mainMenu',
          type: 'AsyncSelect',
          entity: 'menu_containers',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'LandingHeader',
    type: 'header',
    title: 'Landing main menu',
  },

  thumbnails: {
    '1x': thumbnail,
    '2x': thumbnail2x,
  },
}
