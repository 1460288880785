import thumbnailsEventDetail from '@griddo-images/thumbnails/EventDetail.png'

import IntroForm from '@schemas/modules/IntroForm'
import SocialShare from '@schemas/modules/SocialShare'
import SpeakersCollection from '@schemas/modules/SpeakersCollection'
import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { getCurrentDate } from '@utils/CurrentDate'
import { basicModuleWhiteList } from '@schemas/modules'
import eventCategories from '@constants/eventCategories'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'template',
  displayName: 'Event Detail',
  component: 'EventDetail',
  dataPacks: ['EVENTS'],
  type: { label: 'Events', value: 'events', mode: 'detail' },
  content: [
    {
      title: 'Event Title',
      type: 'HeadingField',
      key: 'eventTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
    },
    {
      title: 'Main image',
      type: 'ComponentContainer',
      whiteList: ['BackgroundImage'],
      key: 'mainImage',
      mandatory: true,
      helptext: 'Recommended image size: 1296x626',
    },
    {
      title: 'Summary',
      type: 'TextField',
      key: 'eventSummary',
      mandatory: true,
      helptext: 'For distributors',
    },
    {
      title: 'Status',
      type: 'RadioGroup',
      key: 'eventStatus',
      options: [
        {
          title: 'Pre-event',
          name: 'Pre-event',
          value: 'preevent',
        },
        {
          title: 'Ongoing',
          name: 'Ongoing',
          value: 'ongoing',
        },
        {
          title: 'Post-event',
          name: 'Post-event',
          value: 'postevent',
        },
      ],
      mandatory: true,
    },
    {
      title: 'Date',
      type: 'DateField',
      key: 'eventDate',
      mandatory: true,
    },
    {
      title: 'Time',
      type: 'TextField',
      key: 'eventTime',
      hideable: true,
    },
    {
      title: 'Mode',
      type: 'RadioGroup',
      key: 'eventMode',
      options: [
        ...eventCategories.eventModes.map((item) => {
          return {
            title: item.label,
            name: item.label,
            value: item.label,
          }
        }),
      ],
      mandatory: true,
    },
    {
      title: 'Type',
      type: 'RadioGroup',
      key: 'eventType',
      options: [
        ...eventCategories.eventTypes.map((item) => {
          return {
            title: item.label,
            name: item.label,
            value: item.label,
          }
        }),
      ],
      mandatory: true,
    },
    {
      title: 'Language',
      type: 'RadioGroup',
      key: 'eventLang',
      options: [
        ...eventCategories.langs.map((item) => {
          return {
            title: item.label,
            name: item.label,
            value: item.label,
          }
        }),
      ],
      mandatory: true,
    },
    {
      title: 'Area',
      type: 'AsyncCheckGroup',
      source: 'AREA',
      key: 'area',
      mandatory: true,
    },
    {
      title: 'Topic',
      type: 'AsyncCheckGroup',
      source: 'EVENTS_TOPICS',
      key: 'eventTopic',
      mandatory: true,
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: ['IntroForm', 'SpeakersCollection', ...basicModuleWhiteList],
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      mandatory: true,
    },
    {
      title: 'En Date',
      type: 'DateField',
      key: 'eventEndDate',
      helptext: 'For microformats',
    },
    {
      title: 'Performer',
      type: 'TextField',
      key: 'performer',
      helptext: 'For microformats',
    },
    {
      title: 'Status (microformat)',
      type: 'TextField',
      key: 'statusM',
      helptext: 'For microformats',
    },
    {
      title: 'Price',
      type: 'TextField',
      key: 'price',
      helptext: 'For microformats',
    },
    {
      title: 'Availability',
      key: 'availability',
      type: 'ConditionalField',
      options: [
        {
          name: 'InStock',
          value: 'InStock',
          title: 'In Stock',
        },
        {
          name: 'SoldOut',
          value: 'SoldOut',
          title: 'Sol dOut',
        },
        {
          name: 'PreOrder',
          value: 'PreOrder',
          title: 'Pre Order',
        },
      ],
      helptext: 'For microformats',
    },
  ],

  default: {
    type: 'template',
    templateType: 'EventDetail',
    eventTitle: { tag: 'h1', content: 'Title' },
    mainImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 90,
      color: '#001c47',
    },
    eventSummary: 'Lorem ipsum',
    eventStatus: 'preevent',
    eventDate: getCurrentDate(),
    eventTime: '00:00',
    eventEndDate: getCurrentDate(),
    mainSection: {
      component: 'Section',
      name: 'Related Content',
      modules: [
        { ...IntroForm.default, nesting: true },
        { ...SpeakersCollection.default, component: 'SpeakersCollection' },
        { ...SocialShare.default },
      ],
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
    performer: 'Universitat Carlemany',
    price: '',
    statusM: '',
    availability: '',
  },

  thumbnails: {
    '1x': thumbnailsEventDetail,
    '2x': thumbnailsEventDetail,
  },
}
