import thumbnailsDownloadForm from '@griddo-images/thumbnails/DownloadForm.png'
import Button from '@schemas/components/Button'

const defaultBtn = { ...Button.default }
defaultBtn.appearance = 'primary01'
defaultBtn.buttonText = 'Descargar información'

export default {
  schemaType: 'module',
  displayName: 'Download Form',
  component: 'DownloadForm',
  dataPacks: null,
  category: 'forms',

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'TextField',
          key: 'title',
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'TextField',
          key: 'subtitle',
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'TextField',
          key: 'description',
          mandatory: true,
          hideable: true,
        },
        {
          title: 'Descargar',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: false,
        },
      ],
    },
  ],

  default: {
    component: 'DownloadForm',
    title: '¿Te interesa?',
    subtitle: 'Déjanos tus datos',
    description: 'Accederás a toda la información',
    buttonText: 'Descargar información',
    button: { ...defaultBtn },
  },

  thumbnails: {
    '1x': thumbnailsDownloadForm,
    '2x': thumbnailsDownloadForm,
  },
}
