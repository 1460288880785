import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'component',
  displayName: 'Program USP',
  component: 'ProgramUsp',
  dataPacks: ['PROGRAMS'],
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'icon',
          hideable: true,
        },
        {
          title: 'USP',
          type: 'TextField',
          key: 'usp',
          hideable: true,
        },
      ],
    },
  ],

  default: {
    component: 'ProgramUsp',
    icon: defaultBackgroundImage,
    usp: 'Titulación Oficial Europea',
  },
}
