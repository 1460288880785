import buttonslink from '@griddo-images/buttons/link.png'
import buttonssecondary from '@griddo-images/buttons/secondary.png'
import buttonsprimary02 from '@griddo-images/buttons/primary02.png'
import buttonsprimary01 from '@griddo-images/buttons/primary01.png'

export default {
  schemaType: 'component',
  displayName: 'Link',
  component: 'Link',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        { title: 'Text', type: 'TextField', key: 'text' },
        { title: 'URL', type: 'UrlField', key: 'url', advanced: true },
      ],
    },

    {
      title: 'config',
      fields: [
        {
          title: 'Style',
          key: 'style',
          type: 'VisualUniqueSelection',
          columns: 3,
          options: [
            { value: 'primary', img: buttonsprimary01 },
            { value: 'secondary', img: buttonssecondary },
            { value: 'link', img: buttonslink },
          ],
        },
      ],
    },
  ],

  default: {
    component: 'Link',
    text: 'Link',
    style: 'primary',
    isLink: 'link',
    elements: [],
    url: {
      url: '',
      linkToURL: null,
      newTab: true,
      noFollow: false,
      size: null,
      icon: null,
      linkContainer: null,
    },
  },

  thumbnails: {},
}
