// import thumbnailsCypherCard from '@griddo-images/thumbnails/CypherCard.png'

export default {
  schemaType: 'component',
  displayName: 'Cypher Card',
  component: 'CypherCard',
  dataPacks: null,

  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Featured Text',
          type: 'TextField',
          key: 'featuredText',
          mandatory: true,
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
      ],
    },
  ],

  default: {
    component: 'CypherCard',
    featuredText: '1',
    title: { content: 'Lorem ipsum', tag: 'h2' },
    detail: 'Lorem ipsum',
  },

  thumbnails: {
    // '1x': thumbnailsCypherCard,
    // '2x': thumbnailsCypherCard,
  },
}
