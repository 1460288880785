import thumbnailsGrid from '@griddo-images/thumbnails/Grid.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Grid',
  component: 'Grid',
  category: 'interactive',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Description',
          type: 'RichText',
          key: 'description',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Grid elements',
          type: 'ComponentArray',
          whiteList: ['GridElement'],
          key: 'elements',
          maxItems: null,
          mandatory: true,
        },
      ],
    },
  ],
  default: {
    component: 'Grid',
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    elements: [
      {
        component: 'GridElement',
        image: defaultBackgroundImage,
        title: { content: 'Title', tag: 'h2' },
        subtitle: 'Subtitle',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'secondary',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'GridElement',
        image: defaultBackgroundImage,
        title: { content: 'Title', tag: 'h2' },
        subtitle: 'Subtitle',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'secondary',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
      {
        component: 'GridElement',
        image: defaultBackgroundImage,
        title: { content: 'Title', tag: 'h2' },
        subtitle: 'Subtitle',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor nisi, rutrum amet tortor sed elementum. Commodo, proin tincidunt facilisi pellentesque morbi.',
        button: {
          component: 'Button',
          buttonText: 'Link',
          appearance: 'secondary',
          isLink: 'link',
          elements: [],
          url: {
            href: '',
            linkToURL: null,
            newTab: false,
            noFollow: false,
          },
        },
      },
    ],
  },

  thumbnails: {
    '1x': thumbnailsGrid,
    '2x': thumbnailsGrid,
  },
}
