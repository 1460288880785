export default {
  schemaType: 'component',
  displayName: 'Accordion Element',
  component: 'AccordionElement',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: false,
          mandatory: true,
        },
        {
          title: 'Description',
          type: 'TextField',
          key: 'description',
          hideable: false,
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'AccordionElement',
    title: {
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      tag: 'h2',
    },
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut dictum augue euismod nisi imperdiet condimentum. Pellentesque fringilla eleifend justo, eget sagittis leo auctor eu. Duis at ligula leo. Nam sagittis tristique dolor ac pharetra. Cras venenatis tellus leo, eget facilisis metus imperdiet non. Morbi quis efficitur ante. Vestibulum quis dictum ligula. Etiam ultricies maximus augue, sed pharetra tellus luctus a. Pellentesque vestibulum tempor leo, et congue quam vestibulum sed.',
  },
}
