import thumbnailsProgramFilter from '@griddo-images/thumbnails/ProgramFilter.png'

export default {
  schemaType: 'module',
  displayName: 'Program Filter',
  component: 'ProgramFilter',
  category: 'search_and_discover',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'CTA',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          mandatory: true,
        },
      ],
    },
  ],

  default: {
    component: 'ProgramFilter',
    title: { content: 'Title', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Buscar',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
  },

  thumbnails: {
    '1x': thumbnailsProgramFilter,
    '2x': thumbnailsProgramFilter,
  },
}
