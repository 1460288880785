import thumbnailsContact from '@griddo-images/thumbnails/Contact.png'

import BasicMoreInfo from '@schemas/components/BasicMoreInfo'
import { defaultBackgroundImage } from '@constants/dam'
import { breakpoints } from '@constants/theme'

export default {
  schemaType: 'template',
  displayName: 'Contact',
  component: 'ContactTemplate',
  dataPacks: null,
  type: { label: 'Static', value: 'static' },

  content: [
    {
      title: 'Form Icon',
      type: 'ImageField',
      key: 'icon',
      hideable: true,
    },
    {
      title: 'Form Title',
      type: 'HeadingField',
      key: 'contactTitle',
      default: { tag: 'h2', content: 'Title' },
      options: [
        { value: 'h1', label: 'H1' },
        { value: 'h2', label: 'H2' },
        { value: 'h3', label: 'H3' },
        { value: 'h4', label: 'H4' },
        { value: 'span', label: 'span' },
        { value: 'p', label: 'p' },
      ],
      advanced: true,
      mandatory: true,
    },
    {
      title: 'Form Subtitle',
      type: 'TextField',
      key: 'subtitle',
      hideable: true,
    },
    {
      title: 'Form Description',
      type: 'TextField',
      key: 'description',
      hideable: true,
    },
    {
      title: 'CTA',
      type: 'ComponentContainer',
      whiteList: ['Button'],
      key: 'button',
    },
    {
      title: 'Related Content',
      type: 'ComponentArray',
      maxItems: null,
      whiteList: [
        'AccordionCollection',
        'BasicContent',
        'CardCollection',
        'CypherCollection',
        'DownloadCollection',
        'MultimediaContent',
        'ColumnText',
        'BasicText',
        'Spacer',
        'StudyPlan',
        'Testimonials',
        'HighlightedContent',
        'FeaturedBlock',
        'FeaturedAudiovisual',
        'GoToTop',
        'HorizontalTabs',
        'CarouselCollection',
        'VerticalTabs',
        'MixedCollection',
        'ColumnsPresentation',
        'ProgramsDistributor',
        'Grid',
        'LogoCollection',
        'SocialShare',
        'StaffDistributor',
        'NewsDistributor',
        'EventDistributor',
        'InteractiveBasicContent',
      ],
      key: 'mainSection',
    },
    {
      title: 'More Info',
      type: 'ComponentArray',
      whiteList: ['BasicMoreInfo'],
      key: 'moreInfo',
      maxItems: 1,
      hideable: true,
    },
  ],

  default: {
    type: 'template',
    templateType: 'ContactTemplate',
    icon: {
      component: 'LinkableImage',
      file: defaultBackgroundImage,
    },
    contactTitle: { tag: 'h2', content: 'Contacto' },
    subtitle:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing',
    button: {
      component: 'Button',
      buttonText: 'Enviar',
      appearance: 'secondary',
      isLink: 'link',
      elements: [],
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    mainSection: {
      component: 'Section',
      name: 'Main Section',
      modules: [
        {
          component: 'MultimediaContent',
          nesting: false,
          icon: defaultBackgroundImage,
          title: { content: 'Curabitur diam nulla', tag: 'h2' },
          subtitle: { content: 'Curabitur diam nulla', tag: 'h2' },
          intro: 'Lorem ipsum dolor sit amet.',
          content: '<p>Lorem ipsum dolor sit amet.</p>\n',
          linkableimage: {
            component: 'LinkableImage',
            file: defaultBackgroundImage,
            veil: 50,
          },
          button: {
            component: 'Button',
            buttonText: 'Link',
            appearance: 'secondary',
            isLink: 'link',
            elements: [],
            url: {
              href: '',
              linkToURL: null,
              newTab: false,
              noFollow: false,
            },
          },
          kind: 'VideoComponent',
          elements: [
            {
              component: 'VideoComponent',
              url: '',
              thumbnail: {
                component: 'LinkableImage',
                file: defaultBackgroundImage,
                veil: 50,
              },
              responsive: [
                {
                  breakpoint: breakpoints.default,
                  width: '400px',
                },
                {
                  breakpointName: 's',
                  breakpoint: breakpoints.s,
                  width: '600px',
                },
                {
                  breakpointName: 'm',
                  breakpoint: breakpoints.m,
                  width: '900px',
                },
                {
                  breakpointName: 'l',
                  breakpoint: breakpoints.l,
                  width: '1100px',
                },
                {
                  breakpointName: 'xl',
                  breakpoint: breakpoints.l,
                  width: '1300px',
                },
              ],
            },
          ],
          layout: 'L001',
          theme: 'light',
          nested: false,
        },
      ],
      sectionPosition: 2,
    },
    moreInfo: {
      component: 'Section',
      name: 'More Info',
      modules: [{ ...BasicMoreInfo.default }],
    },
  },

  thumbnails: {
    '1x': thumbnailsContact,
    '2x': thumbnailsContact,
  },
}
