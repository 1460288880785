import thumbnailsCypherCollection from '@griddo-images/thumbnails/CypherCollection.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslight from '@griddo-images/themes/light.png'
import layoutsCypherCollectionlayout002 from '@griddo-images/layouts/CypherCollection/layout002.png'
import layoutsCypherCollectionlayout001 from '@griddo-images/layouts/CypherCollection/layout001.png'
import { defaultBackgroundImage } from '@constants/dam'

export default {
  schemaType: 'module',
  displayName: 'Cypher Collection',
  component: 'CypherCollection',
  category: 'collections',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Use image as background',
          type: 'ConditionalField',
          key: 'conditionalBackground',
          mandatory: true,
          options: [
            {
              value: true,
              title: 'Yes',
              name: 'yes',
            },
            {
              value: false,
              title: 'No',
              name: 'no',
            },
          ],
          fields: [
            {
              title: 'Background Image',
              type: 'ComponentContainer',
              whiteList: ['BackgroundImage'],
              key: 'backgroundImage',
              condition: true,
            },
          ],
        },
        {
          title: 'Icon',
          type: 'ImageField',
          key: 'image',
          hideable: true,
          helptext: 'Recommended size: 1920x1080',
        },
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Cards',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          key: 'elements',
          whiteList: ['CypherCard'],
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Layout',
          key: 'layout',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'L001',
              img: layoutsCypherCollectionlayout001,
            },
            {
              value: 'L002',
              img: layoutsCypherCollectionlayout002,
            },
          ],
          columns: 2,
        },
        {
          title: 'Style',
          key: 'theme',
          type: 'VisualUniqueSelection',
          mandatory: true,
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'blue',
              img: themesblue,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],

  default: {
    component: 'CypherCollection',
    conditionalBackground: false,
    backgroundImage: {
      component: 'BackgroundImage',
      file: defaultBackgroundImage,
      veil: 0,
    },
    image: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { Subtitle: 'Title', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    kind: 'CypherCard',
    elements: [
      {
        component: 'CypherCard',
        featuredText: '1',
        title: { content: 'Lorem ipsum', tag: 'h2' },
        detail: 'Lorem ipsum',
      },
      {
        component: 'CypherCard',
        featuredText: '1',
        title: { content: 'Lorem ipsum', tag: 'h2' },
        detail: 'Lorem ipsum',
      },
      {
        component: 'CypherCard',
        featuredText: '1',
        title: { content: 'Lorem ipsum', tag: 'h2' },
        detail: 'Lorem ipsum',
      },
    ],
    layout: 'L001',
    theme: 'light',
  },

  thumbnails: {
    '1x': thumbnailsCypherCollection,
    '2x': thumbnailsCypherCollection,
  },
}
