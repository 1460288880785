import thumbnailsSocialShare from '@griddo-images/thumbnails/SocialShare.png'
import themesyellow from '@griddo-images/themes/yellow.png'
import themesultraLightYellow from '@griddo-images/themes/ultraLightYellow.png'
import themesblue from '@griddo-images/themes/blue.png'
import themeslightBlue from '@griddo-images/themes/lightBlue.png'
import themesultraLightBlue from '@griddo-images/themes/ultraLightBlue.png'
import themeslight from '@griddo-images/themes/light.png'

export default {
  schemaType: 'module',
  displayName: 'Social Share',
  component: 'SocialShare',
  dataPacks: null,
  category: 'content',
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Title',
          type: 'HeadingField',
          key: 'title',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
      ],
    },
    {
      title: 'config',
      fields: [
        {
          title: 'Theme',
          key: 'theme',
          type: 'VisualUniqueSelection',
          options: [
            {
              value: 'light',
              img: themeslight,
            },
            {
              value: 'ultraLightBlue',
              img: themesultraLightBlue,
            },
            {
              value: 'lightBlue',
              img: themeslightBlue,
            },
            {
              value: 'blue',
              img: themesblue,
            },
            {
              value: 'ultraLightYellow',
              img: themesultraLightYellow,
            },
            {
              value: 'yellow',
              img: themesyellow,
            },
          ],
          columns: 8,
        },
      ],
    },
  ],
  default: {
    component: 'SocialShare',
    title: {
      content: 'Text',
      tag: 'h2',
    },
    theme: 'light',
  },
  thumbnails: {
    '1x': thumbnailsSocialShare,
    '2x': thumbnailsSocialShare,
  },
}
