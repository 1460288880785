import { useState, useEffect } from 'react'
import { getWindow } from 'ssr-window'
const window = getWindow()

function getLocalStorageOrDefault(key, hasWindow) {
  const value = hasWindow && window.localStorage?.getItem(key)
  return value !== null ? value : ''
}

export function useLocalStorage(key) {
  const hasWindow = typeof window !== 'undefined'
  const [value, setValue] = useState(getLocalStorageOrDefault(key, hasWindow))
  useEffect(() => {
    hasWindow && window.localStorage.setItem(key, value)
  }, [key, value])
  return [value, setValue]
}
