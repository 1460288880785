import { useState, useEffect } from 'react'
import { getWindow } from 'ssr-window'

const window = getWindow()

function getSessionStorageOrDefault(key, defaultValue) {
  const stored =
    typeof window !== 'undefined' && window.sessionStorage?.getItem(key)
  if (!stored) return defaultValue
  try {
    return JSON.parse(String(stored))
  } catch (err) {
    return stored
  }
}

export function useSessionStorage(
  key,
  defaultValue,
  resetSessionStorage = false
) {
  const [value, setValue] = useState(defaultValue)
  if (!resetSessionStorage) getSessionStorageOrDefault(key, defaultValue)

  useEffect(() => {
    typeof window !== 'undefined' &&
      window.sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
