import thumbnailsLogoCollection from '@griddo-images/thumbnails/LogoCollection.png'
import { defaultBackgroundImage } from '@constants/dam'

import LogoCard from '@schemas/components/LogoCard'

export default {
  schemaType: 'module',
  displayName: 'Logo Collection',
  component: 'LogoCollection',
  category: 'collections',
  dataPacks: null,
  configTabs: [
    {
      title: 'content',
      fields: [
        {
          title: 'Nesting',
          type: 'ConditionalField',
          key: 'nesting',
          mandatory: true,
          condition: false,
          options: [
            {
              name: 'nested',
              value: true,
              title: 'Nested module',
            },
            {
              name: 'no_nested',
              value: false,
              title: 'No nested',
            },
          ],
          fields: [
            {
              title: 'Icon',
              type: 'ImageField',
              key: 'icon',
              hideable: true,
              condition: false,
            },
            {
              title: 'Title',
              type: 'HeadingField',
              key: 'title',
              default: { tag: 'h2', content: 'Title' },
              options: [
                { value: 'h1', label: 'H1' },
                { value: 'h2', label: 'H2' },
                { value: 'h3', label: 'H3' },
                { value: 'h4', label: 'H4' },
                { value: 'span', label: 'span' },
                { value: 'p', label: 'p' },
              ],
              advanced: true,
              hideable: true,
              condition: false,
            },
          ],
        },
        {
          title: 'Subtitle',
          type: 'HeadingField',
          key: 'subtitle',
          default: { tag: 'h2', content: 'Title' },
          options: [
            { value: 'h1', label: 'H1' },
            { value: 'h2', label: 'H2' },
            { value: 'h3', label: 'H3' },
            { value: 'h4', label: 'H4' },
            { value: 'span', label: 'span' },
            { value: 'p', label: 'p' },
          ],
          advanced: true,
          hideable: true,
        },
        {
          title: 'Detail',
          type: 'RichText',
          key: 'detail',
          hideable: true,
          html: true,
        },
        {
          title: 'Button',
          type: 'ComponentContainer',
          whiteList: ['Button'],
          key: 'button',
          hideable: true,
        },
        {
          title: 'Elements',
          type: 'ComponentArray',
          elementUniqueSelection: true,
          mandatory: true,
          maxItems: null,
          key: 'elements',
          whiteList: ['LogoCard'],
        },
      ],
    },
  ],

  default: {
    component: 'LogoCollection',
    nesting: false,
    icon: defaultBackgroundImage,
    title: { content: 'Title', tag: 'h2' },
    subtitle: { content: 'Subtitle', tag: 'h2' },
    detail:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi dignissim ut nibh eget porttitor. Nunc eleifend mollis arcu.',
    button: {
      component: 'Button',
      buttonText: 'Link',
      appearance: 'link',
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
    },
    kind: 'LogoCard',
    elements: [
      { ...LogoCard.default },
      { ...LogoCard.default },
      { ...LogoCard.default },
    ],
  },

  thumbnails: {
    '1x': thumbnailsLogoCollection,
    '2x': thumbnailsLogoCollection,
  },
}
